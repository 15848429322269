<template>
  <div class="edit-tourist-dialog">
    <w-dialog
      ref="touristDialogRef"
      title="信息编辑"
      width="40%"
      top="25vh"
      @wConfirm="handleSure"
    >
      <el-form
        ref="touristFormRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="name" label="游客姓名">
              <el-input
                v-model="formData.name"
                autocomplete="off"
                placeholder="请输入游客姓名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="mobile" label="游客手机号">
              <el-input
                v-model="formData.mobile"
                autocomplete="off"
                placeholder="请输入游客手机号"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="idcard" label="身份证">
              <el-input
                v-model="formData.idcard"
                autocomplete="off"
                placeholder="请输入身份证"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formData.commit_auth == 1">
            <el-form-item class="upload" label="人脸" prop="face_url">
              <img-upload
                :needImg="false"
                :limit="1"
                uploadTitle="人脸照片"
                :fileList="fileList"
                @uploadFile="uploadFile"
                @deleteFile="deleteFile"
                suggestText=""
              ></img-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { checkStr } from "@/utils/common.js";
import { ElMessage } from "element-plus";
import imgUpload from "@/components/img-upload/img-upload.vue";
import { useStore } from "vuex";

export default {
  emits: ["submit"],
  components: {
    imgUpload,
  },
  setup(props, { emit }) {
    const store = useStore();

    const fileList = ref([]);
    const uploadFile = (obj) => {
      if (formData.value.authentication_status == 2) {
        ElMessage.warning({
          message: "当前游客正在实名中！",
        });
        return false;
      }
      fileList.value = [{ file_url: obj.standard_url }];
      formData.value.face_url = obj.key;
    };
    /** 图片删除 */
    const deleteFile = () => {
      fileList.value = [];
      formData.value.face_url = "";
    };
    const formData = ref({
      name: "",
      mobile: "",
      idcard: "",
      face_url: "",
    });

    const touristDialogRef = ref(null);
    const formRules = {
      name: [
        {
          required: true,
          message: "请输入游客姓名",
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: "请输入游客手机号",
          trigger: "blur",
        },
      ],
      idcard: [
        {
          required: true,
          message: "请输入身份证",
          trigger: "blur",
        },
      ],
    };

    function openDialog(data) {
      formData.value = JSON.parse(JSON.stringify(data));
      fileList.value = [];
      formData.value.face_url = "";
      store.dispatch("getQiniuData");

      touristDialogRef.value.show();
    }
    function closeDialog() {
      touristDialogRef.value.close();
    }
    function closeDialogLoading() {
      touristDialogRef.value.isLoading = false;
    }
    const touristFormRef = ref(null);
    function handleSure() {
      if (touristFormRef.value) {
        touristFormRef.value.validate((valid) => {
          if (valid) {
            touristDialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            if (!checkStr(data.name, "chinese")) {
              ElMessage.warning({
                message: "游客姓名不正确！",
              });
              closeDialogLoading();
              return false;
            }
            if (!checkStr(data.mobile, "phone")) {
              ElMessage.warning({
                message: "游客手机号格式不正确！",
              });
              closeDialogLoading();
              return false;
            }
            if (!checkStr(data.idcard, "card")) {
              ElMessage.warning({
                message: "身份证格式不正确！",
              });
              closeDialogLoading();
              return false;
            }
            // if (formData.value.otr_face_url && !data.face_url) {
            //   ElMessage.warning({
            //     message: "请录入人脸！",
            //   });
            //   return false;
            // }
            // if (formData.value.otr_face_url == data.face_url) {
            //   data.face_url = "";
            // }

            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      touristFormRef,
      handleSure,
      openDialog,
      touristDialogRef,
      fileList,
      closeDialog,
      deleteFile,
      uploadFile,
      closeDialogLoading,
    };
  },
};
</script>

<style lang="scss">
.edit-tourist-dialog {
  .img-upload > ul li {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
  .el-form-item {
    .el-form-item__content {
      .el-image {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}
</style>
